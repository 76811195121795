import script from "./Contact.vue?vue&type=script&setup=true&lang=ts"
export * from "./Contact.vue?vue&type=script&setup=true&lang=ts"

import "./Contact.vue?vue&type=style&index=0&id=03870978&lang=scss"

const __exports__ = script;

export default __exports__
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QAvatar,QIcon,QBtn,QPopupProxy,QBanner,QSeparator});
